import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { Login } from "@microsoft/mgt-react"
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { EnumAutenticacaoTipo } from '../../../../app/modules/services/core/_enums'

const itemClass = 'ms-1 ms-lg-3'
const btnIconClass = 'svg-icon-1'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const {auth} = useAuth()
  const user = auth?.User
  const {logout} = useAuth()
  const {config} = useLayout()
  const navigate = useNavigate()

  return (
    <div className='app-navbar flex-shrink-0'>

      <div className={clsx('app-navbar-item me-5', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      {auth && (
        <>
        {auth.Tipo === EnumAutenticacaoTipo.AspNetID ? (
          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              className={clsx('cursor-pointer symbol', userAvatarClass)}
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <img src={user?.FotoDataUrl ? user?.FotoDataUrl : toAbsoluteUrl("/media/avatars/blank.png")} alt='' />
            </div>
            <HeaderUserMenu />
          </div>
        ) : (
          <div className={clsx('app-navbar-item', itemClass)}>
            <Login
              logoutInitiated={() => {
                logout()
                navigate('/')
              }}
              loginView="avatar"
              className={"cursor-pointer symbol"}
            />
          </div>
        )}
        </>                
      )}      

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Mostrar menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
