import {ErrorMessage, Field, FormikProvider, useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useBlockUI, useHttpClient} from '../../../../modules/services/Bundle'
import {RequestMethod} from '../../../../modules/services/core/_enums'
import { defaultPerfil, defaultValidationSchemas } from './helpers/PerfilHelper'
import { IDadosMenuModel } from '../../../../modules/services/core/_models'

interface IPerfilDados {
  Id?: number
  Acao: string
}

const Perfil = () => {
  const httpClient = useHttpClient()
  const location = useLocation()
  const informacao = location.state as IPerfilDados
  const navigate = useNavigate()
  const [tipoAcaoPage, setTipoAcaoPage] = useState('')
  const [dadosMenu, setDadosMenu] = useState<IDadosMenuModel[]>([])
  const {setBlocking} = useBlockUI()

  const formik = useFormik({
    initialValues: defaultPerfil,
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values) => {
      if (tipoAcaoPage === 'Adicionar') {
        await httpClient.request({
          method: RequestMethod.POST,
          endpoint: '/Administracao/Perfil/Adicionar',
          data: values,
          ifSuccessRedirectTo: '/administracao/perfis/lista',
        })
      }

      if (tipoAcaoPage === 'Editar') {
        await httpClient.request({
          method: RequestMethod.PUT,
          endpoint: '/Administracao/Perfil/Editar',
          data: values,
          ifSuccessRedirectTo: '/administracao/perfis/lista',
        })
      }
    },
  })

  const onChangeInputsCheckAll = (e: any) => {
    const checked = e.target.checked
    const idInterface = parseInt(e.target.getAttribute('data-interface') ?? '0')
    const inputsInterfaces = document.querySelectorAll(`.interface-${idInterface}`)

    if (checked) {
      
      inputsInterfaces.forEach((i: Element) => {
        let input = i as HTMLInputElement
        const idFuncionalidade = parseInt(input.getAttribute('data-funcionalidade') ?? '0')
        const idAcao = parseInt(input.getAttribute('data-acao') ?? '0')
        const idFVisualizar = parseInt(input.getAttribute('data-visualizar') ?? '0')
        const newValues = formik.values

        if (idFVisualizar && idFVisualizar > 0 && idFVisualizar !== idFuncionalidade && formik.values.Permissoes.find((x) => x.IdFuncionalidade === idFVisualizar) === undefined) {
          newValues.Permissoes.push({
            Id: 0,
            IdPerfil: formik.values.Id ?? 0,
            IdFuncionalidade: idFVisualizar,
            Funcionalidade: {
              Id: idFVisualizar,
              IdAcao: idAcao,
              IdInterface: idInterface,
              Codigo: '',
              Ativa: true,
              Acao: '',
            },
          })

          const inputFVisuliazar = document.getElementById(`funcionalidade-${idFVisualizar}`)
          if (inputFVisuliazar) {
            let novoInput = inputFVisuliazar as HTMLInputElement
            novoInput.checked = true
          }
        }

        if (idFVisualizar && idFVisualizar > 0 && idFVisualizar !== idFuncionalidade && formik.values.Permissoes.find((x) => x.IdFuncionalidade === idFVisualizar) !== undefined) {
          const inputFVisuliazar = document.getElementById(`funcionalidade-${idFVisualizar}`)
          if (inputFVisuliazar) {
            let novoInput = inputFVisuliazar as HTMLInputElement
            novoInput.checked = true
          }
        }

        if (newValues.Permissoes.find((x) => x.IdFuncionalidade === idFuncionalidade) === undefined) {
          newValues.Permissoes.push({
            Id: 0,
            IdPerfil: formik.values.Id ?? 0,
            IdFuncionalidade: idFuncionalidade,
            Funcionalidade: {
              Id: idFuncionalidade,
              IdAcao: idAcao,
              IdInterface: idInterface,
              Codigo: '',
              Ativa: true,
              Acao: '',
            },
          })
        }

        formik.setValues(newValues)

        return (input.checked = true)

      })
    } else {

      inputsInterfaces.forEach((i: Element) => {
        let input = i as HTMLInputElement
        const idFuncionalidade = parseInt(input.getAttribute('data-funcionalidade') ?? '0')
        const newValues = formik.values
        const permissoes = newValues.Permissoes.filter((x) => x.IdFuncionalidade !== idFuncionalidade)
        newValues.Permissoes = permissoes
        formik.setValues(newValues)
        return (input.checked = false)
      })

    }
  }

  const onChangeInputsCheck = (
    e: any,
    idFuncionalidade: number,
    idAcao: number,
    idInterface: number,
    idFVisualizar: number | undefined,
  ) => {
    const valor = e.target.checked
    
    if (valor) {
      const newValues = formik.values

      if (idFVisualizar && idFVisualizar > 0 && idFVisualizar !== idFuncionalidade && formik.values.Permissoes.find((x) => x.IdFuncionalidade === idFVisualizar) === undefined) {
        newValues.Permissoes.push({
          Id: 0,
          IdPerfil: formik.values.Id ?? 0,
          IdFuncionalidade: idFVisualizar,
          Funcionalidade: {
            Id: idFVisualizar,
            IdAcao: idAcao,
            IdInterface: idInterface,
            Codigo: '',
            Ativa: true,
            Acao: '',
          },
        })

        const inputFVisuliazar = document.getElementById(`funcionalidade-${idFVisualizar}`)
        if (inputFVisuliazar) {
          var novoInput = inputFVisuliazar as HTMLInputElement
          novoInput.checked = true
        }
      }

      if (idFVisualizar && idFVisualizar > 0 && idFVisualizar !== idFuncionalidade && formik.values.Permissoes.find((x) => x.IdFuncionalidade === idFVisualizar) !== undefined) {
        const inputFVisuliazar = document.getElementById(`funcionalidade-${idFVisualizar}`)
        if (inputFVisuliazar) {
          let novoInput = inputFVisuliazar as HTMLInputElement
          novoInput.checked = true
        }
      }

      if (newValues.Permissoes.find((x) => x.IdFuncionalidade === idFuncionalidade) === undefined) {
        newValues.Permissoes.push({
          Id: 0,
          IdPerfil: formik.values.Id ?? 0,
          IdFuncionalidade: idFuncionalidade,
          Funcionalidade: {
            Id: idFuncionalidade,
            IdAcao: idAcao,
            IdInterface: idInterface,
            Codigo: '',
            Ativa: true,
            Acao: '',
          },
        })
      }

      formik.setValues(newValues)   

    } else {

      const newValues = formik.values
      const permissoes = newValues.Permissoes.filter((x) => x.IdFuncionalidade !== idFuncionalidade)
      newValues.Permissoes = permissoes
      formik.setValues(newValues)

    }
  }

  const obterPerfil = async (id: number) => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Perfil/Obter',
      blockFree: true,
      queryObject: {
        idPerfil: id,
      },
    })
    if (response.success && response.payload) formik.setValues(response.payload)
  }

  const obterDadosMenu = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Perfil/ObterDadosMenu',
      blockFree: true,
    })
    if (response.success && response.payload) setDadosMenu(response.payload)
  }

  useEffect(() => {
    const obterDados = async () => {

      setBlocking(true)

      if (informacao && informacao.Acao && informacao.Acao !== '') setTipoAcaoPage(informacao.Acao)

      if (informacao && informacao.Id) await obterPerfil(informacao.Id)

      await obterDadosMenu()

      setBlocking(false)
    }

    obterDados()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='card mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{tipoAcaoPage} Perfil</h3>
          </div>
        </div>

        <div className='card-body'>
          <FormikProvider value={formik}>
            <div className='w-100'>
              <div className='row'>
                <div className='col-lg-12 fv-row mb-5'>
                  <label className='form-label required'>Nome</label>
                  <Field
                    name={`Nome`}
                    placeholder='Nome'
                    className='form-control form-control-lg form-control-solid'
                    maxLength={150}
                    value={formik.values.Nome}
                    disabled={tipoAcaoPage === 'Visualizar' ? true : false}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Nome' />
                  </div>
                </div>

                <div className='col-lg-12 fv-row mb-5'>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    {dadosMenu &&
                      dadosMenu.length > 0 &&
                      dadosMenu.map((data, index) => {
                        return (
                          <li key={`li-${index}`} className='nav-item'>
                            <a
                              className={`nav-link text-active-primary text-hover-primary text-uppercase fw-bold ${index === 0 ? 'active' : ''}`}
                              data-bs-toggle='tab'
                              href={`#kt_tab_${data.Categoria}`}
                            >
                              {data.CategoriaNome}
                            </a>
                          </li>
                        )
                      })}
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div className='w-100 d-flex align-items-center mb-2'>
                      <label className='form-label m-0'>Módulos</label>
                      <label style={{width: '100%'}} className='ms-2 d-flex justify-content-end '>
                        {' '}
                        <hr className='m-0' style={{width: '100%'}} />{' '}
                      </label>
                    </div>
                    {dadosMenu && dadosMenu.length > 0 && dadosMenu.map((menu, index) => {
                        return (
                          <div
                            key={`tab-${menu.Categoria}`}
                            className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
                            id={`kt_tab_${menu.Categoria}`}
                            role='tabpanel'
                          >
                            {menu.Modulos && menu.Modulos.length > 0 && menu.Modulos.map((modulo) => {                              
                              return (
                                <div
                                  key={`accordion-modulo-${modulo.Id}`}
                                  className='accordion mb-2'
                                  id={`kt_accordion_modulo_${modulo.Id}`}
                                >
                                  <div className='accordion-item'>
                                    <h2
                                      className='accordion-header'
                                      id={`kt_accordion_modulo_header_${modulo.Id}`}
                                    >
                                      <button
                                        className='accordion-button fs-4 fw-bold collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target={`#kt_accordion_modulo_body_${modulo.Id}`}
                                        aria-expanded='false'
                                        aria-controls={`kt_accordion_modulo_body_${modulo.Id}`}
                                      >
                                        <i className={`bi fs-3 ${modulo.Icone} me-3`}></i>
                                        {modulo.Nome}
                                      </button>
                                    </h2>
                                    <div
                                      id={`kt_accordion_modulo_body_${modulo.Id}`}
                                      className='accordion-collapse collapse'
                                      aria-labelledby={`kt_accordion_modulo_header_${modulo.Id}`}
                                      data-bs-parent={`#kt_accordion_modulo_${modulo.Id}`}
                                    >
                                      <div className='accordion-body'>
                                        <div className='w-100 d-flex align-items-center mb-2'>
                                          <label className='form-label m-0'>Aplicações</label>
                                          <label style={{width: '100%'}} className='ms-2 d-flex justify-content-end '>
                                            {' '}
                                            <hr className='m-0' style={{width: '100%'}} />{' '}
                                          </label>
                                        </div>
                                        {modulo.Aplicacoes && modulo.Aplicacoes.length > 0 && modulo.Aplicacoes.map((aplicacao) => {                              
                                          return (
                                            <div
                                              key={`accordion-aplicacao-${aplicacao.Id}`}
                                              className='accordion mb-2'
                                              id={`kt_accordion_aplicacao_${aplicacao.Id}`}
                                            >
                                              <div className='accordion-item'>
                                                <h2
                                                  className='accordion-header'
                                                  id={`kt_accordion_aplicacao_header_${aplicacao.Id}`}
                                                >
                                                  <button
                                                    className='accordion-button fs-4 fw-bold collapsed'
                                                    type='button'
                                                    data-bs-toggle='collapse'
                                                    data-bs-target={`#kt_accordion_aplicacao_body_${aplicacao.Id}`}
                                                    aria-expanded='false'
                                                    aria-controls={`kt_accordion_aplicacao_body_${aplicacao.Id}`}
                                                  >
                                                    <i className={`bi fs-3 ${aplicacao.Icone} me-3`}></i>
                                                    {aplicacao.Nome}
                                                  </button>
                                                </h2>
                                                <div
                                                  id={`kt_accordion_aplicacao_body_${aplicacao.Id}`}
                                                  className='accordion-collapse collapse'
                                                  aria-labelledby={`kt_accordion_aplicacao_header_${aplicacao.Id}`}
                                                  data-bs-parent={`#kt_accordion_aplicacao_${aplicacao.Id}`}
                                                >
                                                  <div className='accordion-body'>
                                                    <div className='w-100 d-flex align-items-center mb-2'>
                                                      <label className='form-label m-0'>Interfaces</label>
                                                      <label style={{width: '100%'}} className='ms-2 d-flex justify-content-end '>
                                                        {' '}
                                                        <hr className='m-0' style={{width: '100%'}} />{' '}
                                                      </label>
                                                    </div>
                                                    {aplicacao.Interfaces && aplicacao.Interfaces.length > 0 && aplicacao.Interfaces.map((interfaceDados) => {
                                                      if (interfaceDados.Funcionalidades && interfaceDados.Funcionalidades.length > 0) {
                                                        return (
                                                          <div
                                                            key={`accordion-interface-${interfaceDados.Id}`}
                                                            className='accordion mb-2'
                                                            id={`kt_accordion_interface_${interfaceDados.Id}`}
                                                          >
                                                            <div className='accordion-item'>
                                                              <h2
                                                                className='accordion-header'
                                                                id={`kt_accordion_interface_header_${interfaceDados.Id}`}
                                                              >
                                                                <button
                                                                  className='accordion-button fs-4 fw-bold collapsed'
                                                                  type='button'
                                                                  data-bs-toggle='collapse'
                                                                  data-bs-target={`#kt_accordion_interface_body_${interfaceDados.Id}`}
                                                                  aria-expanded='false'
                                                                  aria-controls={`kt_accordion_interface_body_${interfaceDados.Id}`}
                                                                >
                                                                  <i className={`bi fs-3 ${interfaceDados.Icone} me-3`}></i>
                                                                  {interfaceDados.Nome}
                                                                </button>
                                                              </h2>
                                                              <div
                                                                id={`kt_accordion_interface_body_${interfaceDados.Id}`}
                                                                className='accordion-collapse collapse'
                                                                aria-labelledby={`kt_accordion_interface_header_${interfaceDados.Id}`}
                                                                data-bs-parent={`#kt_accordion_interface_${interfaceDados.Id}`}
                                                              >
                                                                <div className='accordion-body row'>
                                                                  <div className='col-lg-4 fv-row'>
                                                                    <label className='form-label'></label>
                                                                    <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between m-0 border border-1 rounded px-5 py-2'>
                                                                      <label className='form-label m-0'>
                                                                        Selecionar todos
                                                                      </label>
                                                                      <input
                                                                        type='checkbox'
                                                                        name={`interface.${interfaceDados.Id}`}
                                                                        onChange={onChangeInputsCheckAll}
                                                                        className={`form-check-input h-25px w-40px`}
                                                                        data-interface={interfaceDados.Id}
                                                                        disabled={tipoAcaoPage === 'Visualizar' ? true : false}
                                                                      ></input>
                                                                    </div>
                                                                  </div>
                                                                  <div className='col-lg-8'></div>

                                                                  {interfaceDados.Funcionalidades && interfaceDados.Funcionalidades.length > 0 && interfaceDados.Funcionalidades.map((funcionalidade) => {
                                                                        const idFVisualizar =
                                                                          interfaceDados.Funcionalidades.find(
                                                                            (x) => x.Acao === 'Visualizar'
                                                                          )?.Id
                                                                        return (
                                                                          <div
                                                                            key={`funcionalidade-${funcionalidade.Id}`}
                                                                            className='col-lg-4 fv-row'
                                                                          >
                                                                            <label className='form-label'></label>
                                                                            <div className='form-check form-switch form-check-custom form-check-solid d-flex justify-content-between m-0 border border-1 rounded px-5 py-2'>
                                                                              <label className='form-label m-0'>
                                                                                {funcionalidade.Acao}
                                                                              </label>
                                                                              <input
                                                                                type='checkbox'
                                                                                name={`funcionalidade.${funcionalidade.Id}`}
                                                                                id={`funcionalidade-${funcionalidade.Id}`}
                                                                                onChange={(e) =>
                                                                                  onChangeInputsCheck(
                                                                                    e,
                                                                                    funcionalidade.Id,
                                                                                    funcionalidade.IdAcao,
                                                                                    funcionalidade.IdInterface,
                                                                                    idFVisualizar,
                                                                                  )
                                                                                }
                                                                                data-visualizar={idFVisualizar}
                                                                                data-funcionalidade={funcionalidade.Id}
                                                                                data-acao={funcionalidade.IdAcao}
                                                                                data-interface={funcionalidade.IdInterface}
                                                                                defaultChecked={
                                                                                  (tipoAcaoPage === 'Editar' || tipoAcaoPage === 'Visualizar') &&
                                                                                  formik.values.Permissoes &&
                                                                                  formik.values.Permissoes.find((x) => x.IdFuncionalidade === funcionalidade.Id) !== undefined ? true : false
                                                                                }
                                                                                className={`form-check-input h-25px w-40px interface-${interfaceDados.Id}`}
                                                                                disabled={
                                                                                  (idFVisualizar && funcionalidade.Id === idFVisualizar && formik.values.Permissoes.filter((x) => x.Funcionalidade?.IdInterface === funcionalidade.IdInterface).length > 1) ||
                                                                                  tipoAcaoPage === 'Visualizar' ? true : false
                                                                                }
                                                                              ></input>
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                      }
                                                                    )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      }
                                                      
                                                      return (<div key={`accordion-interface-${interfaceDados.Id}`}></div>)
                                                    })}
                                                    {(!aplicacao.Interfaces || aplicacao.Interfaces.length === 0) && (<div className='text-center'><label>Essa aplicação ainda não possui interfaces configuradas</label></div>)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                        {(!modulo.Aplicacoes || modulo.Aplicacoes.length === 0) && (<div className='text-center'><label>Esse módulo ainda não possui aplicações configuradas</label></div>)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                            {(!menu.Modulos || menu.Modulos.length === 0) && (<div className='text-center'><label>Essa categoria ainda não possui módulos configurados</label></div>)}
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </FormikProvider>
        </div>
        <div className='card-footer py-4 d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-lg btn-secondary me-5'
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          {tipoAcaoPage !== 'Visualizar' && tipoAcaoPage !== '' && (
            <button
              type='button'
              onClick={() => formik.handleSubmit()}
              className='btn btn-lg btn-primary'
            >
              {tipoAcaoPage}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export {Perfil}
