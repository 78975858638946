import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../modules/services/core/Breadcrumb'
import { Perfil } from './components/Perfil'

const profileBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Conta',
    path: '',
  }
]

const ContaPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='perfil'
          element={
            <>
              <BreadcrumbLayout props={profileBreadCrumbs}>Perfil</BreadcrumbLayout>
              <Perfil />
            </>
          }
        />        
        <Route path='*' element={<Navigate to='/dashboard' />} />   
        <Route index element={<Navigate to='/conta/perfil' />} />
      </Route>
    </Routes>
  )
}

export default ContaPage
