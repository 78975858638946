/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import './AuthLayout.css'
import { useLocation } from 'react-router-dom'

const AuthLayout = () => {
  const location = useLocation();

  return (
    <div className='d-flex flex-column flex-root min-vh-100 layout-background'>
      <div className='d-flex flex-column flex-column-fluid flex-lg-row justify-content-center justify-content-xl-between'>
        <div className='d-none d-xl-flex flex-row w-50'>
          <div className='d-flex flex-end flex-column'>
            <div className='d-flex pt-20 px-20'>
              <div className='d-flex flex-column'>
                <div className='text-white fw-bolder display-6 display-lg-5'>
                  <p>Bem-vindo(a) ao novo<br></br>Portal Instituto Embelleze!</p>
                </div>
                <div className='text-white fw-semibold fs-3 fs-lg-2'>
                  <p>Sua plataforma única para acesso aos recursos educacionais, administrativos e de gestão. Navegue com facilidade e aproveite ao máximo todos os recursos que preparamos para você.</p>
                </div>
              </div>
            </div>
            <div>
              <img
                alt='Logo'
                src={toAbsoluteUrl(`/media/embelleze/embelleze-${location.pathname === '/registration' ? 'registration' : 'login'}.png`)}
                className='w-100 h-auto mh-550px'
              />  
            </div>                      
          </div>
        </div>

        <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center w-100 w-xl-50 p-5 p-lg-20'>
          <div className='layout-login-background d-flex flex-column align-items-stretch flex-center rounded-4 shadow-lg w-100 w-md-600px p-10'>
            <div className='my-3 text-center'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/embelleze/embelleze-logo.png')}
                className='w-100 h-auto mw-325px mw-xl-400px'
              />
            </div>
            <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10'>
              <Outlet />
            </div>
            <div className='d-flex justify-content-center flex-stack px-lg-10'>
              <div className='d-flex fw-semibold text-primary fs-base gap-5'>
                <a href='/termos' target='_blank'>
                  Termos
                </a>

                <a href='/' target='_blank'>
                  Planos
                </a>

                <a href='/' target='_blank'>
                  Contato
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
