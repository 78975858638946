import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import {useAlert, useHttpClient } from '../../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, RequestMethod } from '../../../../../modules/services/core/_enums';
import { IconButton } from '@mui/material';
import { useAuth } from '../../../../../modules/auth';

interface Data {
  Id: number,
  Nome: string,
  Ativo: boolean,
}

interface Column {
  id: keyof Data;
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'NOME'
  },
  {
    id: 'Ativo',
    label: 'ATIVO',
  },
];

function createData(
  Id: number,
  Nome: string,
  Ativo: boolean
): Data {
  return { Id, Nome, Ativo};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}

const TablePerfis: React.FC = () => {  
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const alert = useAlert()
  const navigate = useNavigate()
  const [search, setSearch] = React.useState<string>('')
  const {auth} = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes

  const onKeyDownBuscar = (search: string) => {
    setSearch(search);
    resquestTable(page, orderBy, order, rowsPerPage, search, true);    
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-embelleze-header'>
          {columns.map((columns) => (
            <TableCell
            className='table-embelleze-header-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='table-embelleze-header-cell fw-bolder'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, search: any = null, block: boolean = false) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Administracao/Perfil/Listar',
        blockFree: block,
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Search: search,
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.Nome, data.Ativo)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.Nome);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  return (
    <>
      <form className='form'>
        <div>
          <div className='row' >         
            {/* begin::Input group */}
            <div className='mb-2 col-lg-4'>
              <input 
                type='text'
                onChange={e => onKeyDownBuscar(e.target.value)} 
                placeholder='Pesquisar (Nome)' 
                className={`form-control form-control-lg form-control-solid`}
                value={search}
              ></input>
            </div>
            {/* end::Input group */}
          </div>
          <Box sx={{ width: '100%' }}>
            <Paper className='table-embelleze-border ' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  className='table-embelleze-header'
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-embelleze-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-embelleze-cell' align="left">{row.Nome}</TableCell>
                          <TableCell className='table-embelleze-cell' align='left'>
                            <span className={`badge badge-light-${row.Ativo ? 'success' : 'danger'}`}>{row.Ativo ? 'Sim' : 'Não'}</span>
                          </TableCell>
                          <TableCell className='table-embelleze-cell' align="center">

                            <IconButton 
                              className={`text-gray-800 icon-button-dark-hover`}
                              title='Visualizar' 
                              onClick={() => { 
                                navigate('/administracao/perfis/visualizar',
                                { 
                                  state: 
                                  { 
                                    Id: row.Id,
                                    Acao: "Visualizar"
                                  }
                                })
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                </svg>
                            </IconButton>
                            
                            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Franqueadora_Administração_Portal_Perfis_Editar'])) && (
                              <IconButton 
                                className={`text-gray-800 icon-button-dark-hover`}
                                title='Editar' 
                                onClick={() => { 
                                  navigate('/administracao/perfis/editar',
                                  { 
                                    state: 
                                    { 
                                      Id: row.Id,
                                      Acao: "Editar"
                                    }
                                  })
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                              </IconButton>
                            )}     

                            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Franqueadora_Administração_Portal_Perfis_Ativar/Desativar'])) && (
                              <IconButton 
                                  className={`text-gray-800`}
                                  title={`${row.Ativo ? "Desativar" : "Ativar"}`}
                                  onClick={() => {
                                      alert.createDialog({
                                          html: `<label>Realmente deseja ${row.Ativo ? "desativar" : "ativar"} esse perfil?</label>
                                          <div class='mt-5'>
                                            <div class="alert alert-danger d-flex align-items-center p-5">
                                              <div class="d-flex flex-column">
                                                <h5 class="mb-1">Atenção</h5>
                                                <span>As permissões dos usuários vinculadas a esse perfil também serão ${row.Ativo ? "desativadas" : "ativadas"}!</span>
                                              </div>
                                            </div>
                                          </div>`,
                                          confirmAction: async () => {                              
                                              httpClient.request({
                                                  method: RequestMethod.PUT,
                                                  endpoint: '/Administracao/Perfil/AtivarDesativar',
                                                  data: row.Id
                                              }).then((response) => {
                                                  if (response.success) resquestTable(page, orderBy, order, rowsPerPage)                                   
                                              })
                                          }
                                      })
                                  }}
                                  >
                                  {
                                    row.Ativo ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-toggle-on" viewBox="0 0 16 16">
                                      <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-toggle-off" viewBox="0 0 16 16">
                                      <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                    </svg>
                                  }
                              </IconButton>    
                            )}     

                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows === 0 && (
                      <TableRow className='table-embelleze-row'>
                        <TableCell className='table-embelleze-cell text-center' colSpan={columns.length + 1}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-embelleze-footer'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </form>
    </>    
  )
}

export {TablePerfis}