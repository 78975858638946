import {RequestMethod} from './_enums'

export interface RequestModel {
  method: RequestMethod
  endpoint: string
  data?: any
  queryObject?: any
  headers?: any
  ifSuccessRedirectTo?: string
  blockFree?: boolean
}

export interface FileModel {
  Nome: string
  Extensao: string
  Tamanho: number
  Tipo: string
  Base64: string
}

export const defaultRequestModel: Partial<RequestModel> = {
  data: null,
  queryObject: null,
  headers: null,
  ifSuccessRedirectTo: '',
}

export interface ISelectModel {
  Id: number              
  Nome: string 
  Texto?: string
}

export interface JwtTokenModel {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  roles?: string[];
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
}

export interface IAcaoModel {
  Id: number,
  Nome: string,
}

export interface IFuncionalidadeModel {
  Id: number,
  IdInterface: number,
  IdAcao: number,
  Codigo: string,
  Ativa: boolean,
  Acao: string,
}

export interface IPermissaoModel {
  Id: number,
  IdFuncionalidade: number,
  IdPerfil: number,
  Funcionalidade?: IFuncionalidadeModel,
}

export interface IInterfaceModel {
  Id: number,
  IdAplicacao: number,
  Nome: string,
  Rota: string,
  Icone: string,
  Funcionalidades: IFuncionalidadeModel[],
}

export interface IAplicacaoModel {
  Id: number,
  IdModulo: number,
  Nome: string,
  Url: string,
  Icone: string,
  Interfaces: IInterfaceModel[],
}

export interface IModuloModel {
  Id: number,
  Nome: string,
  Categoria: number,
  CategoriaNome: string,
  Icone: string,
  Aplicacoes: IAplicacaoModel[],
}

export interface IDadosMenuModel {
  Categoria: number,
  CategoriaNome: string,
  Modulos: IModuloModel[],
}

export interface IFranquiaModel {
  Id: number,
  Nome: string,
  Alias: string,
  Codigo?: number,
}

export interface IPerfilModel {
  Id: number,
  Nome: string,
  Permissoes: IPermissaoModel[],
}

export interface IUsuarioModel {
  Id: string,
  Tipo: number,
  Nome: string,
  Email: string,
  Celular: string,
  Senha: string,
  Perfis: number[],
  Franquias: number[]
}