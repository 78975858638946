/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../../modules/services/core/Breadcrumb'
import { Usuarios } from './components/Usuarios'
import { Usuario } from './components/Usuario'
import { EnumPermissoes, EnumRoles } from '../../../modules/services/core/_enums'
import { useAuth } from '../../../modules/auth'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
]

const wizardsBreadCrumbsUsuarios: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
  {
    titulo: 'Usuários',
    path: '/administracao/usuarios/lista',
  }
]

const UsuariosPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Usuários</BreadcrumbLayout>
                <Usuarios />
              </>
            }
          />

          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Franqueadora_Administração_Portal_Usuários_Adicionar)) && (
              <Route
                path='adicionar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Adicionar Usuário</BreadcrumbLayout>
                    <Usuario />
                  </>
                }
              /> 
          )}
          
          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Franqueadora_Administração_Portal_Usuários_Editar)) && (
              <Route
                path='editar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Editar Usuário</BreadcrumbLayout>
                    <Usuario />
                  </>
                }
              /> 
          )}          

          <Route
            path='visualizar'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Visualizar Usuário</BreadcrumbLayout>
                <Usuario />
              </>
            }
          /> 

          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route index element={<Navigate to='/administracao/usuarios/lista' />} />
        </Route>
      </Routes>
    )
}

export {UsuariosPage}
