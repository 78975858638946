export enum EnumRoles {
  Administrador = 'Administrador',
}

export enum EnumPermissoes {
  'Franqueadora_Administração_Portal_Perfis_Adicionar' = '27C4BBD3C6D066DFFDCCD291DD62528F982FACE75C60A42C61B029CE0CC7F247',
  'Franqueadora_Administração_Portal_Perfis_Ativar/Desativar' = '3FE674F71F31978FCB60036808D85E6B9DF4B135129D79EEBC5C00EAACFA6698',
  'Franqueadora_Administração_Portal_Perfis_Deletar' = '4D713997B35FA8BB8F63293951DC9ABC46D3CC20720D1E3DEE04572B72E4A734',
  'Franqueadora_Administração_Portal_Perfis_Editar' = 'DE64BC92617F7E703825DED7ED9BE39768BF37D4B533A979D00B0DEDDD950C4E',
  'Franqueadora_Administração_Portal_Perfis_Visualizar' = '712B2F74A47DB50D459236EFCE9B95202C26033E48CD85CBDE6BB1CDB6470D18',
  'Franqueadora_Administração_Portal_Usuários_Adicionar' = '7AED85EB53F7573A0513D5A3D039D99DEDDA7BA51EA2A6724E8745135BCEB129',
  'Franqueadora_Administração_Portal_Usuários_Ativar/Desativar' = '02517CC47EBD95A4C97BC08023CBB66B5E28F276C629585EA6F07364FAE438EB',
  'Franqueadora_Administração_Portal_Usuários_Editar' = '54C5A6A61FB8F35E8806276CA11EB6A97C12A7408C029B053D92ED8BA2E837C8',
  'Franqueadora_Administração_Portal_Usuários_Visualizar' = 'F5A96C30C9E70541CBFBC39DB158346FB98CA66D6894EE4C3600185E8C3C1105',
}

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum EnumTaskStatus {
  "Processando" = 1,
  "Incompleto" = 2,
  "Completo" = 3,
  "Erro" = 4,
}

export enum EnumAutenticacaoTipo {
  "AspNetID" = 1,
  "MSEntraID" = 2,
}

export enum EnumUsuarioTipo {
  "Franqueadora (Microsoft Interno)" = 1,
  "Parceiros (Microsoft Externo)" = 2,
  "Alunos e Franquias (Domínio Externo)" = 3,
}