/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import { AuthModel } from '../core/_models'
import { getToken, useHttpClient } from '../../services/Bundle'
import { EnumAutenticacaoTipo, RequestMethod } from '../../services/core/_enums'
import { Providers, ProviderState } from '@microsoft/mgt-element'

const UpdateAuth = () => {
    const {auth, saveAuth} = useAuth()
    const location = useLocation()
    const httpClient = useHttpClient()
    const navigate = useNavigate()

    const getAuth = async () => {
      if (auth) {      
        const response = await httpClient.request({
          endpoint: `/Account/GetAuth`,
          method: RequestMethod.GET,
          blockFree: true,
        });
  
        if (response.success && response.payload) {
          const newAuth: AuthModel = response.payload
          saveAuth(newAuth) 
        }
      }
    }

    const updateToken = async () => {
      if (auth) {
        const newAuth = {...auth}
        newAuth.Token = await getToken();
        saveAuth(newAuth) 
      }   
    }

    const onProviderUpdated = async () => {
      const provider = Providers.globalProvider;
      const isSignedIn = provider && provider.state === ProviderState.SignedIn;

      if (isSignedIn) {
        if (auth) {
          await updateToken()
          await getAuth()
        }
        else {
          const newAuth: AuthModel = {
            Token: await getToken(),
            Tipo: EnumAutenticacaoTipo.MSEntraID,
            User: {
              IdUsuario: '',
              DataCadastro: '',
              Nome: '',
              Email: '',
              Celular: '',
              AutenticacaoEmDoisFatores: false,
              FotoDataUrl: '',
            },
            Roles: [],
            Permissoes: []
          }
          
          saveAuth(newAuth) 

          navigate('/dashboard')
        }
      }      
    }

    const onActiveAccountChanged = async () => {
      if (auth) {
        await updateToken()
        navigate('/dashboard')
        window.location.reload()
      }      
    }

    useEffect(() => {  
      Providers.onProviderUpdated(onProviderUpdated)
      Providers.onActiveAccountChanged(onActiveAccountChanged)
  
      return () => {
        Providers.onProviderUpdated(onProviderUpdated)
        Providers.onActiveAccountChanged(onActiveAccountChanged)
      }
    }, [])

    useEffect(() => {
      getAuth()
    }, [location])

    return (
        <>
        </>
    )
}

export {UpdateAuth}
