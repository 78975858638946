import {FC} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {auth} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>          
          {auth ? (
            <Route path='/*' element={<PrivateRoutes />} />      
          ) : (
            <Route path='/*' element={<AuthPage />} />  
          )}
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
