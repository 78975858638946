/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useBlockUI, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { defaultValidationSchemas, initialValues } from './helpers/UsuarioHelper'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { IFranquiaModel, IPerfilModel } from '../../../../modules/services/core/_models'

interface Dados {
  idUsuario: string
  tipo: number
  visualizar: boolean
  title: string
}

const Usuario = () => {
  const httpClient = useHttpClient()
  const navigate = useNavigate()  
  const location = useLocation()
  const state = location.state as Dados
  const informacao: Dados = state
  const {setBlocking} = useBlockUI()
  const [franquias, setFranquias] = useState<IFranquiaModel[]>([])
  const [perfis, setPerfis] = useState<IPerfilModel[]>([])
  
  const formik = useFormik({
      initialValues: {...initialValues},
      validationSchema: defaultValidationSchemas,
      onSubmit: async (values) => {      
        
        values.Celular = values.Celular.replace(/\D/g, '');
        
        await httpClient.request({
          method: (informacao && informacao.idUsuario) ? RequestMethod.PUT : RequestMethod.POST,
          endpoint: `/Administracao/Usuario/${(informacao && informacao.idUsuario) ? "Editar" : "Adicionar"}`,
          data: values,
          ifSuccessRedirectTo: '/administracao/usuarios/lista'
        })
      },
  })

  const obterUsuario = async () => {
    
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Usuario/Obter',
      blockFree: true,
      queryObject: {
        tipo: informacao.tipo,
        idUsuario: informacao.idUsuario
      }
    })
    
    if(response.success && response.payload) {

      if(response.payload.Tipo.toString() === EnumUsuarioTipo['Franqueadora (Microsoft Interno)'].toString()){
        response.payload.Email = response.payload.Email.split('@')[0];
        response.payload.Senha = '@Ab12345678';
      }

      formik.setValues(response.payload)
    }

  }

  const obterFranquias = async () =>{

    setFranquias([])

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Franquia/ObterParaSelect',
      blockFree: true
    }).then((response) => {
      if(response.success && response.payload) setFranquias(response.payload)
    })
    
  }

  const obterPerfis = async () =>{

    setPerfis([])

    await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Perfil/ObterParaSelect',
      blockFree: true
    }).then((response) => {
      if(response.success && response.payload) setPerfis(response.payload)
    })
    
  }

  const obterDados = async() => {
    
    setBlocking(true)

    if (informacao && informacao.idUsuario && informacao.tipo) await obterUsuario()
    
    await obterPerfis()

    await obterFranquias()

    setBlocking(false)

  }

  useEffect(() => {
    if(!informacao || !informacao.idUsuario) {
      formik.setFieldValue("Perfis", [])
      formik.setFieldValue("Franquias", [])
    }
  },[formik.values.Tipo])

  useEffect(() => {
    obterDados()
  },[informacao])

  return (
    <>
    <div className='card mb-5 mb-xl-10'>

      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{informacao.title} Usuário</h3>
        </div>
      </div>

      <div className='card-body'>
        <FormikProvider value={formik}>
          <div className='w-100'>
            <form noValidate >

              <div className="row">  
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label required'>Tipo</label>
                  <Field
                    as='select'
                    name='Tipo'
                    className='form-select form-select-lg form-select-solid'
                    value={formik.values.Tipo}
                    disabled={informacao && informacao.idUsuario}
                  >
                    {Object.keys(EnumUsuarioTipo).map(key => (
                      
                      parseInt(key) > 0 && (
                        <option key={key} value={parseInt(key)}>
                          {EnumUsuarioTipo[parseInt(key)]}
                        </option>
                      )
                      
                    ))}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Tipo' />
                  </div>
                </div>
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label required'>Nome</label>
                  <Field
                    name='Nome'
                    placeholder='Nome do usuário'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.Nome}
                    disabled={informacao && informacao.idUsuario && informacao.visualizar}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Nome' />
                  </div>
                </div>
              </div>  
              
              <div className="row">                  
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label required'>Email</label>
                  <div className={formik.values.Tipo.toString() === EnumUsuarioTipo['Franqueadora (Microsoft Interno)'].toString() ? 'input-suffix-iembelleze' : ''}>
                    <Field
                      name='Email'                    
                      placeholder='Email do usuário'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Email}
                      disabled={((informacao && informacao.idUsuario && informacao.visualizar) || formik.values.Tipo === EnumUsuarioTipo['Parceiros (Microsoft Externo)'] || formik.values.Tipo === EnumUsuarioTipo['Alunos e Franquias (Domínio Externo)'])}
                    />  
                  </div>                                   
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Email' />
                  </div>
                </div>
                {(!informacao || !informacao.idUsuario) && (formik.values.Tipo.toString() === EnumUsuarioTipo['Franqueadora (Microsoft Interno)'].toString()) && (
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Senha</label>
                    <Field
                      name='Senha'
                      placeholder='Senha do usuário'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Senha}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Senha' />
                    </div>
                  </div>
                )}
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label required'>Celular</label>
                  <InputMask
                    mask={'(99) 99999-9999'}
                    placeholder='Celular do usuário'
                    type='text'
                    {...formik.getFieldProps('Celular')}                  
                    className='form-control form-control-lg form-control-solid'                    
                    disabled={informacao && (informacao.idUsuario !== undefined && informacao.idUsuario !== '') && informacao.visualizar}
                  ></InputMask>                                   
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Celular' />
                  </div>
                </div>
                {(formik.values.Tipo.toString() === EnumUsuarioTipo['Parceiros (Microsoft Externo)'].toString() || formik.values.Tipo.toString() === EnumUsuarioTipo['Alunos e Franquias (Domínio Externo)'].toString()) && (
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label'>Franquias (Unidades)</label>
                    <Select 
                      placeholder='Selecione...' 
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      {...formik.getFieldProps('Franquias')}
                      options={franquias.map((data) => ({
                        value: data.Id,
                        label: data.Nome
                      }))} 
                      isDisabled={informacao && informacao.visualizar}
                      value={franquias && franquias.length > 0 && formik.values.Franquias && formik.values.Franquias.length > 0 ? formik.values.Franquias.map((idFranquia) => ({
                        value: idFranquia,
                        label: franquias.find(x => x.Id === idFranquia)?.Nome ?? ''
                      })) : []}
                      isSearchable
                      isMulti
                      onChange={(e) => formik.setFieldValue("Franquias", e.map((data) => { return data.value }))}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Franquias' />
                    </div>
                  </div>
                )}
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label'>Perfis (Permissões)</label>
                  <Select 
                    placeholder='Selecione...' 
                    className='react-select-styled react-select-lg react-select-solid'
                    classNamePrefix='react-select'
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                    {...formik.getFieldProps('Perfis')}
                    options={perfis.map((data) => ({
                      value: data.Id,
                      label: data.Nome
                    }))} 
                    isDisabled={informacao && informacao.visualizar}
                    value={perfis && perfis.length > 0 && formik.values.Perfis && formik.values.Perfis.length > 0 ? formik.values.Perfis.map((idPerfil) => ({
                      value: idPerfil,
                      label: perfis.find(x => x.Id === idPerfil)?.Nome ?? ''
                    })) : []}
                    isSearchable
                    isMulti
                    onChange={(e) => formik.setFieldValue("Perfis", e.map((data) => { return data.value }))}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Perfis' />
                  </div>
                </div>
              </div> 

            </form>
          </div>
        </FormikProvider>
      </div>
      <div className="card-footer d-flex justify-content-end py-4">        
        <button className='btn btn-secondary' onClick={() => navigate(-1)}>
          Voltar
        </button>
        {informacao.title && informacao.title !== 'Visualizar' && (
          <button
            type='button'
            onClick={() => formik.handleSubmit()}
            className={`btn btn-lg btn-primary ms-5 ${informacao && informacao.visualizar ? "d-none" : ""}`}
          >
            {informacao.title}
          </button>
        )}        
      </div>
    </div>
    </>
  )
}

export {Usuario}

