/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { PerfisPage } from './perfis/PerfisPage'
import { UsuariosPage } from './usuarios/UsuariosPage'
import { useAuth } from '../../modules/auth'
import { EnumPermissoes, EnumRoles } from '../../modules/services/core/_enums'

const AdministracaoPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />} >
            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Franqueadora_Administração_Portal_Perfis_Visualizar)) && (
                <Route
                    path='perfis/*'
                    element={
                    <>
                        <PerfisPage/>
                    </>
                    }
                />
            )}
            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Franqueadora_Administração_Portal_Usuários_Visualizar)) && (
                <Route
                    path='usuarios/*'
                    element={
                    <>
                        <UsuariosPage/>
                    </>
                    }
                />
            )}            
        </Route>
      </Routes>
    )
}

export {AdministracaoPage}
