import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";
import useEmbellezeRedirect from "../../../../../hooks/useEmbellezeRedirect";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  titleVideo?: string;
  descriptionVideo?: string;
  pathVideo?: string;
  tempoVideo?: string;
  hasBullet?: boolean;
  embellezeRedirectUrl?: string;
  embellezeRedirectRota?: string;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  titleVideo,
  descriptionVideo,
  pathVideo,
  tempoVideo,
  hasBullet = false,
  embellezeRedirectUrl,
  embellezeRedirectRota,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const {goTo} = useEmbellezeRedirect()

  const redirect = () => {
    if (embellezeRedirectUrl && embellezeRedirectRota) goTo(embellezeRedirectUrl, embellezeRedirectRota)
  }

  if (embellezeRedirectUrl && embellezeRedirectRota) {
    return (
      <div className="menu-item">
        <div className="menu-link without-sub" onClick={redirect}>
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {fontIcon ? (
            <span className="menu-icon">
              <i className={clsx("bi fs-3", fontIcon)}></i>
            </span>
          ) : (
            <>
              {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
                <span className="menu-icon">
                  <KTSVG path={icon} className="svg-icon-2" />
                </span>
              )}
            </>
          )}
          <span className="menu-title">{title}</span>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div className="menu-item">
      <Link
        className={clsx("menu-link without-sub", { active: isActive })}
        state={{
          titleVideo: titleVideo,
          descriptionVideo: descriptionVideo,
          pathVideo: pathVideo,
        }}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {fontIcon ? (
          <span className="menu-icon">
            <i className={clsx("bi fs-3", fontIcon)}></i>
          </span>
        ) : (
          <>
            {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
              <span className="menu-icon">
                <KTSVG path={icon} className="svg-icon-2" />
              </span>
            )}
          </>
        )}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };