import { useHttpClient } from "../app/modules/services/Bundle";
import { RequestMethod } from "../app/modules/services/core/_enums";

export default function useEmbellezeRedirect(){
    const httpClient = useHttpClient();

    const goTo = async (url: string, rota: string) => {
           
      const response = await httpClient.request({
        endpoint: `/Account/GetTokenId`,
        method: RequestMethod.GET,
      });

      if (response.success && response.payload) window.location.href = `${url}/r/${response.payload}?redirect=${rota}`;
      
    }   

    return {
        goTo
    }
}