import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {useAlert, useBlockUI, useHttpClient} from '../../../../modules/services/Bundle'
import {EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod} from '../../../../modules/services/core/_enums'
import {useNavigate} from 'react-router-dom'
import { IconButton } from '@mui/material'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAuth } from '../../../../modules/auth'

interface Data {
  Id: string,
  Nome: string,
  Email: string,
  Tipo: number,
  TipoString: string,
  DataCadastroString: string,
  Ativo: boolean,
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [  
  {
    id: 'Nome',
    label: 'NOME',
  },
  {
    id: 'Email',
    label: 'EMAIL',
  },
  {
    id: 'TipoString',
    label: 'TIPO',
  },
  {
    id: 'DataCadastroString',
    label: 'DATA CADASTRO',
  },
  {
    id: 'Ativo',
    label: 'ATIVO',
  },
]

function createData(
    Id: string,
    Nome: string,
    Email: string,
    Tipo: number,
    TipoString: string,
    DataCadastroString: string,
    Ativo: boolean
): Data {
  return {Id, Nome, Email, Tipo, TipoString, DataCadastroString, Ativo}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow className='table-embelleze-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-embelleze-header-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            {columns.label}
          </TableCell>
        ))}
        <TableCell className='table-embelleze-header-cell fw-bolder' align='center'>
          AÇÕES
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const Usuarios = () => {
  const httpClient = useHttpClient()
  const alert = useAlert()
  const blockUI = useBlockUI()
  const navigate = useNavigate()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const [tipo, setTipo] = React.useState<number>(EnumUsuarioTipo['Franqueadora (Microsoft Interno)'])
  const [search, setSearch] = React.useState<string>('')
  const {auth} = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes

  const onKeyDownBuscar = (search: string) => {
    setSearch(search);
    resquestTable(page, orderBy, order, rowsPerPage, search, true);    
  }

  const resquestTable = async (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    search: any = null,
    block: boolean = false,
  ) => {
    let response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Administracao/Usuario/Listar',
      blockFree: block,
      data: {
        Order: orderRq,
        OrderBy: orderByRq,
        Page: pageRq,
        RowsPerPage: rowsPerPageRq,
        Search: search,
      },
      queryObject: {
        tipo: tipo
      }
    })

    if (response.success && response.payload) {
      let newRows = response.payload.data.map((data: Data) => {
        return createData(
          data.Id,
          data.Nome,
          data.Email,
          data.Tipo,
          data.TipoString,
          data.DataCadastroString,
          data.Ativo
        )
      })
      setRows([...newRows])
      setTotal(response.payload.total)
    }
  }

  React.useEffect(() => {
    obterDados()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo])

  const obterDados = async () => {
    blockUI.setBlocking(true)
    await obterDadosTabela()
    blockUI.setBlocking(false)
  }
  
  const obterDadosTabela = async () => {
    await resquestTable(page, orderBy, order, rowsPerPage, search)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, search)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage, search)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10), search)
    setPage(0)
  }

  const emptyRows = rows.length

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Usuários</h3>
          </div>
          <div className='card-toolbar'>            
              <button 
                className='btn btn-primary'
                title='Adicionar'
                onClick={(e) => {
                  navigate('/administracao/usuarios/adicionar', {
                    state: {
                      visualizar: false,
                      title: 'Adicionar'
                    }
                  })
                }}
              >
                <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
                Adicionar
              </button>
          </div>
        </div>
        <div className='card-body'>

          <form noValidate className='form'>
            <div>
              <div className='row' >                
                {/* begin::Input group */}
                <div className='mb-2 col-lg-4'>
                  <select 
                    onChange={e => {
                      setTipo(parseInt(e.target.value))
                    }} 
                    placeholder='Selecione...'
                    className={`form-select form-select-lg form-select-solid`}
                    value={tipo}
                  >
                    {Object.keys(EnumUsuarioTipo).map(key => (
                      
                      parseInt(key) > 0 && (
                        <option key={key} value={parseInt(key)}>
                          {EnumUsuarioTipo[parseInt(key)]}
                        </option>
                      )
                      
                    ))}
                  </select>
                </div>
                {/* end::Input group */}
                {/* begin::Input group */}
                <div className='mb-2 col-lg-4'>
                  <input 
                    type='text'
                    onChange={e => onKeyDownBuscar(e.target.value)} 
                    placeholder='Pesquisar (Nome ou Email)' 
                    className={`form-control form-control-lg form-control-solid`}
                    value={search}
                  ></input>
                </div>
                {/* end::Input group */}
              </div>
              <Box sx={{width: '100%'}}>
                <Paper className='table-embelleze-border ' sx={{width: '100%', mb: 2}}>
                  <TableContainer>
                    <Table className='table-embelleze-header' sx={{minWidth: 750}} aria-labelledby='tableTitle'>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {rows.map((row, index) => {
                          return (
                            <TableRow
                              className='table-embelleze-row'
                              hover
                              tabIndex={-1}
                              key={index}
                              sx={{cursor: 'pointer'}}
                            >
                              <TableCell className='table-embelleze-cell' align='left'>
                                {row.Nome}
                              </TableCell>
                              <TableCell className='table-embelleze-cell' align='left'>
                                {row.Email}
                              </TableCell>
                              <TableCell className='table-embelleze-cell' align='left'>
                                {row.TipoString}
                              </TableCell>
                              <TableCell className='table-embelleze-cell' align='left'>
                                {row.DataCadastroString}
                              </TableCell>
                              <TableCell className='table-embelleze-cell' align='left'>
                                <span className={`badge badge-light-${row.Ativo ? 'success' : 'danger'}`}>{row.Ativo ? 'Sim' : 'Não'}</span>
                              </TableCell>
                              <TableCell className='table-embelleze-cell' align='center'>
                                  <IconButton 
                                      className={`text-gray-800`}
                                      title='Visualizar' 
                                      onClick={() => { 
                                          navigate('/administracao/usuarios/visualizar', { state: { 
                                              idUsuario: row.Id,
                                              tipo: row.Tipo,
                                              visualizar: true,
                                              title: "Visualizar"
                                          }})
                                      }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                      </svg>
                                  </IconButton>  

                                  {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Franqueadora_Administração_Portal_Usuários_Editar)) && (
                                    <IconButton 
                                      className={`text-gray-800`}
                                      title='Editar'
                                      onClick={() => { 
                                        navigate('/administracao/usuarios/editar', { state: { 
                                          idUsuario: row.Id,
                                          tipo: row.Tipo,
                                          visualizar: false,
                                          title: "Editar"
                                        }})
                                      }}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                      </svg>
                                    </IconButton>     
                                  )}

                                  {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Franqueadora_Administração_Portal_Usuários_Ativar/Desativar'])) && (
                                    <IconButton 
                                        className={`text-gray-800`}
                                        title={`${row.Ativo ? "Desativar" : "Ativar"}`}
                                        onClick={() => {
                                            alert.createDialog({
                                                html: `Realmente deseja ${row.Ativo ? "desativar" : "ativar"} esse usuário?`,
                                                confirmAction: async () => {                              
                                                    httpClient.request({
                                                        method: RequestMethod.PUT,
                                                        endpoint: '/Administracao/Usuario/AtivarDesativar',
                                                        data: {
                                                            Id: row.Id,
                                                            Ativo: !row.Ativo,
                                                            Tipo: row.Tipo
                                                        }
                                                    }).then((response) => {
                                                        if (response.success) obterDadosTabela()                                   
                                                    })
                                                }
                                            })
                                        }}
                                        >
                                        {
                                          row.Ativo ? 
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-toggle-on" viewBox="0 0 16 16">
                                            <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8"/>
                                          </svg>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-toggle-off" viewBox="0 0 16 16">
                                            <path d="M11 4a4 4 0 0 1 0 8H8a5 5 0 0 0 2-4 5 5 0 0 0-2-4zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8M0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5"/>
                                          </svg>
                                        }
                                    </IconButton>    
                                  )}   
                                  
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {emptyRows === 0 && (
                          <TableRow className='table-embelleze-row'>
                            <TableCell className='table-embelleze-cell text-center' colSpan={columns.length + 1}>
                              Nenhum registro encontrado
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className='table-embelleze-footer'
                    labelRowsPerPage='linhas por página'
                    rowsPerPageOptions={[10, 25]}
                    component='div'
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {Usuarios}
