/* eslint-disable react-hooks/exhaustive-deps */
import { SidebarMenuItem } from "./SidebarMenuItem";
import { RequestMethod } from "../../../../../app/modules/services/core/_enums";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { useEffect, useState } from "react";
import { IDadosMenuModel } from "../../../../../app/modules/services/core/_models";
import { useHttpClient } from "../../../../../app/modules/services/Bundle";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const httpClient = useHttpClient()
  const {auth} = useAuth()
  const [dadosMenu, setDadosMenu] = useState<IDadosMenuModel[]>([])
  const [token, setToken] = useState<string | undefined>(undefined)
  const [menuCarregado, setMenuCarregado] = useState<boolean>(false)

  const obterDadosMenu = async () => {

    setMenuCarregado(false)

    if (auth) setToken(auth.Token)

    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Account/GetMenuData',
      blockFree: true,
    })

    if (response.success && response.payload) setDadosMenu(response.payload)     

    setMenuCarregado(true)
      
  }

  useEffect(() => {
    if(auth && token && auth.Token !== token) {
      obterDadosMenu()
    }
  }, [auth])

  useEffect(() => {
    if (!menuCarregado) obterDadosMenu()
  }, [])

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Dashboard"
      />

      {!menuCarregado && (
        <div className="menu-item">
          <div className="menu-content pt-4 pb-2">
            <span className="menu-section text-white text-uppercase fs-8 ls-1">
              Carregando...
            </span>
          </div>
        </div>
      )}

      {dadosMenu && dadosMenu.length > 0 && dadosMenu.map((menu) => (
        <div key={`menu-categoria-${menu.Categoria}`}>
          <div className="menu-item">
            <div className="menu-content pt-4 pb-2">
              <span className="menu-section text-white text-uppercase fs-8 ls-1">
                {menu.CategoriaNome}
              </span>
            </div>
          </div>

          {menu.Modulos && menu.Modulos.length > 0 && menu.Modulos.map((modulo) => (
            <SidebarMenuItemWithSub key={`menu-modulo-${modulo.Id}`} title={modulo.Nome} fontIcon={modulo.Icone}>
              {modulo.Aplicacoes && modulo.Aplicacoes.length > 0 && modulo.Aplicacoes.map((aplicacao) => (
                <div key={`menu-aplicacao-${aplicacao.Id}`}>
                  {aplicacao.Nome === 'Portal' ? (
                    <>
                      {aplicacao.Interfaces && aplicacao.Interfaces.length > 0 && aplicacao.Interfaces.map((interfaceDados) => (
                        <SidebarMenuItem 
                          key={`menu-interface-${interfaceDados.Id}`} 
                          to={(aplicacao.Url === window.location.origin.toString()) ? interfaceDados.Rota : ''} 
                          title={interfaceDados.Nome} fontIcon={interfaceDados.Icone} 
                          embellezeRedirectUrl={(aplicacao.Url !== window.location.origin.toString()) ? aplicacao.Url : undefined} 
                          embellezeRedirectRota={(aplicacao.Url !== window.location.origin.toString()) ? interfaceDados.Rota : undefined} 
                        />
                      ))}  
                    </>
                  ) : (
                    <SidebarMenuItemWithSub title={aplicacao.Nome} fontIcon={aplicacao.Icone}>
                      {aplicacao.Interfaces && aplicacao.Interfaces.length > 0 && aplicacao.Interfaces.map((interfaceDados) => (
                        <SidebarMenuItem 
                          key={`menu-interface-${interfaceDados.Id}`} 
                          to={(aplicacao.Url === window.location.origin.toString()) ? interfaceDados.Rota : ''} 
                          title={interfaceDados.Nome} fontIcon={interfaceDados.Icone} 
                          embellezeRedirectUrl={(aplicacao.Url !== window.location.origin.toString()) ? aplicacao.Url : undefined} 
                          embellezeRedirectRota={(aplicacao.Url !== window.location.origin.toString()) ? interfaceDados.Rota : undefined}
                        />
                      ))}    
                    </SidebarMenuItemWithSub>
                  )}
                </div>
              ))}
            </SidebarMenuItemWithSub>
          ))}
        </div>
      ))}
    </>
  );
};

export { SidebarMenuMain };