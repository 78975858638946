import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {useHttpClient} from '../../services/Bundle'
import {AuthModel} from '../core/_models'
import {RequestMethod} from '../../services/core/_enums'
import IconButton from '@mui/material/IconButton'
import { LoginGraph } from './LoginGraph'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('O email é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const navigate = useNavigate()
  const httpClient = useHttpClient()
  const [senhaErrada, setSenhaErrada] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const redirect = urlParams.get('redirect')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      try {
        const response = await httpClient.request({
          method: RequestMethod.POST,
          endpoint: '/Account/SignIn',
          data: {
            Email: values.email,
            Password: values.password,
          },
        })

        if (response.success && response.payload) {
          if (response.payload === 'two-steps') {
            return navigate('/two-steps', {
              state: {
                email: values.email,
                redirect: redirect,
              },
            })
          } else {
            const newAuth: AuthModel = response.payload
            saveAuth(newAuth)
            navigate('/dashboard')
          }
        } else setSenhaErrada(true)

        setSubmitting(false)
        setLoading(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (formik.isValidating) {
      setSenhaErrada(false)
    }
  }, [formik])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <form
      className='form w-100'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Entrar</h1>
        <div className='text-gray-500 fw-semibold fs-2'>Acesse sua conta</div>
      </div>
      {/* begin::Heading */}
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': (formik.touched.email && formik.errors.email) || senhaErrada},
            {
              'is-valid': formik.touched.email && !formik.errors.email && senhaErrada === false,
            }
          )}
          type='email'
          name='email'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <div className='wrapper-olho'>
          <div className={`${formik.touched.password ? 'icon-olho-distancia-maior' : 'icon-olho'}`}>
            <IconButton
              aria-label='visualizar senha'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <i className='fas fa-eye'></i> : <i className='fas fa-eye-slash'></i>}
            </IconButton>
          </div>
          <input
            placeholder='Senha'
            type={showPassword ? 'text' : 'password'}
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              'input-olho',
              {
                'is-invalid': (formik.touched.password && formik.errors.password) || senhaErrada,
              },
              {
                'is-valid':
                  formik.touched.password && !formik.errors.password && senhaErrada === false,
              }
            )}
          />
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5'>
        <div />

        {/* begin::Link */}
        <Link
          to='/forgot-password'
          state={{email: formik.values.email, redirect: redirect}}
          className='link-primary'
          id='esqueci-senha'
        >
          Esqueceu sua senha?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid'>
        <button
          type='submit'
          onClick={formik.submitForm}
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={loading || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Carregando...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6 mt-5'>
        Não possui uma conta?{' '}
        <Link
          to='/registration'
          className='link-primary'
        >
          Cadastre-se
        </Link>
      </div>  

      <div className='text-gray-500 text-center fw-semibold fs-6 mt-2'>
        Não recebeu o email de confirmação?{' '}
        <Link
          to='/resend-confirm-account'
          className='link-primary'
        >
          Reenviar
        </Link>
      </div>

      <div className='text-gray-900 text-center fw-semibold fs-6 my-5'>ou</div>    

      <div className='text-gray-500 text-center fw-semibold fs-6 mb-2'>Caso seja membro da nossa equipe:</div> 
      <LoginGraph />
    </form>
  )
}
